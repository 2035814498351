<template>
<div class="main">
  <VxeOrgTree service="/sys/sysOrganization/treeQueryAuthCommon/communtiy" class="main_left" :expandAll="true" />
  <div class="main_right">
    <VxeBasicTable ref="xGrid" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions" :defaultTrigger="false">
    </VxeBasicTable>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      currNode: {},
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [{
              code: "create",
              name: "新增",
              status: "primary",
              events: {
                click: () => {
                  let { type, communityId, id } = this.currNode;
                  if (type == "2001") {
                    let { data } = this.formOptions;
                    data["parentId"] = id;
                    data["communityId"] = communityId;
                    this.$refs.xGrid.insertEvent();
                  } else {
                    this.$message.error("请选择停车场");
                  }
                },
              },
            },
            { code: "batchDelete", name: "批量删除", status: "danger" },
          ],
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            type: "checkbox",
            width: 60,
          },
          {
            field: "name",
            title: "车位编号",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入车位编号",
              },
            },
          },
          {
            field: "parkingSpaceType",
            title: "车位类型",
            minWidth: 160,
            editRender: {
              name: "$select",
              optionTypeCode: "parkType",
              props: {
                maxlength: 24,
                placeholder: "请选择车位类型",
              },
            },
          },
          {
            field: "parkingSpacePayType",
            title: "缴费类型",
            minWidth: 160,
            editRender: {
              name: "$select",
              optionTypeCode: "paymentType",
              props: {
                maxlength: 24,
                placeholder: "请选择缴费类型",
              },
            },
          },
          {
            field: "area",
            title: "车位面积(平方米)",
            minWidth: 160,
            formatter: ({ cellValue, row, column }) => {
              if (!cellValue) {
                return "/"
              } else {
                return this.$XEUtils.commafy(Number(cellValue), { digits: 2 })
              }
            },
            editRender: {
              name: "$input",
              props: {
                type: "float",
                maxlength: 24,
                placeholder: "请输入车位面积(平方米)",
              },
            },
          },
          {
            field: "status",
            title: "车位状态",
            minWidth: 100,
            editRender: {
              name: "$select",
              optionTypeCode: "parkStatus",
              props: {
                placeholder: "请选择车位状态",
              },
            },
          },
          {
            field: "enabled",
            title: "状态",
            width: 120,
            cellRender: {
              name: "$switch",
              optionTypeCode: "enabledStatus",
              props: {
                openLabel: "启用",
                closeLabel: "禁用",
                openValue: "1",
                closeValue: "0"
              },
              events: {
                change: ({ row }) => {
                  this.$refs.xGrid.submitEvent(row);
                }
              }
            },
          },
          {
            fixed: "right",
            title: "操作",
            width: 160,
            editRender: {
              name: "$VxeGridButtons",
              lists: [{
                  status: "primary",
                  label: "修改",
                  eventName: "queryEvent",
                },
                {
                  status: "danger",
                  label: "删除",
                  eventName: "removeEvent",
                },
              ],
            },
          },
        ],
        editConfig: {},
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 100,
        items: [{
            field: "parentId",
            title: "所属上级",
            span: 24,
            itemRender: {
              name: "$VxeSelectTree",
              props: {
                placeholder: "请选择所属上级",
              },
              expandAll: true,
              events: {
                click: (params, node) => {
                  let { data } = params;
                  if (node.type == '2001') {
                    data["communityId"] = node.communityId;
                    data["parentId"] = node.id;
                  } else {
                    this.$message.error('请选择停车场')
                  }
                },
              },
            },
          },
          {
            field: "name",
            title: "车位编号",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入车位编号",
              },
            },
          },
          {
            field: "area",
            title: "车位面积",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "float",
                max: 100,
                maxlength: 24,
                placeholder: "请输入车位面积(平方米)",
              },
            },
          },
          {
            field: "parkingSpaceType",
            title: "车位类型",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "parkType",
              props: {
                maxlength: 24,
                placeholder: "请选择车位类型",
              },
            },
          },
          {
            field: "parkingSpacePayType",
            title: "缴费类型",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "paymentType",
              props: {
                maxlength: 24,
                placeholder: "请选择缴费类型",
              },
            },
          },
          {
            field: "ownerId",
            title: this.$t("currentOwner"),
            span: 12,
            itemRender: {
              name: "$VxeSelectOwner",
              defaultProps: {
                label: "name",
                value: "id",
              },
              props: {
                suffixIcon: "fa fa-search",
                placeholder: this.$t("currentPlaceholder"),
              },
              events: {
                click: (params, row) => {
                  let { data, } = params;
                  data["property"] = row.id;
                  data["status"] = "1";
                },
              },
            },
          },
          {
            field: "status",
            title: "车位状态",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "parkStatus",
              props: {
                placeholder: "请选择车位状态",
              },
            },
          },
          {
            field: "remark",
            title: "描述",
            span: 24,
            itemRender: {
              name: "$textarea",
              props: {
                showWordCount: true,
                maxlength: "300",
                resize: "none",
                placeholder: "请输入描述内容",
              },
            },
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "取消",
                  },
                },
              ],
            },
          },
        ],
        //表单校验规则
        rules: {
          parentId: [{ required: true, message: "请选择所属上级" }],
          name: [{ required: true, message: "请输入车位编号" }],
          parkingSpaceType: [{ required: true, message: "请选择车位类型" }],
          parkingSpacePayType: [{ required: true, message: "请选择缴费类型" }],
          status: [{ required: true, message: "请选择车位状态" }],
          enabled: [{ required: true, message: "请选择状态" }],
        },
        // 新增功能字段初始化
        data: {
          parentId: "",
          name: "",
          type: "2002",
          parkingSpaceType: "1",
          parkingSpacePayType: "1",
          enabled: "1",
          status: "0",
        },
      },
      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "name",
            title: "车位名称",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入车位名称",
              },
            },
          },
          {
            field: "num",
            title: "车位编号",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入车位编号",
              },
            },
          },
          // {
          //   field: "state",
          //   title: "车位状态",
          //   itemRender: {
          //     name: "$select",
          //     optionTypeCode: "parkStatus",
          //     props: {
          //       placeholder: "请选择车位状态",
          //     },
          //   },
          // },
          {
            align: "center",
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "重置",
                  },
                },
              ],
            },
          },
        ],
        data: {
          name: "",
          state: "",
          num: "",
          type: "2002",
        },
      },
    };
  },

  methods: {
    handleNodeClick(treeData, node) {
      this.currNode = node;
      this.$Tools.buildDataToGrid(this.gridOptions.columns, treeData, "parentId");
      this.$Tools.buildDataToGrid(this.formOptions.items, treeData, "parentId");
      this.searchOptions.data["parentIds"] = [node.id];
      this.$refs.xGrid.getLists();
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
